import React from "react"
import HeroImage from "./components/HeroImage"
import BrandImage from "./components/BrandImage"

export default function GliEroi() {
  return (
    <section className="section-glieroi">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-four-fifths">
            <h1>
              GLI
              <br />
              EROI
            </h1>
            <div className="columns has-text-centered">
              <div className="column">
                <HeroImage
                  fileName="sergio-azzolari.png"
                  alt="Sergio Azzolari"
                />
                <p className="mt-1">
                  <b>Sergio Azzolari</b>
                </p>
                <p>CEO DSquared2</p>
                <p>
                  <i>Il brand hero</i>
                </p>
              </div>
              <div className="column">
                <HeroImage
                  fileName="matteo-battiston.png"
                  alt="Matteo Battiston"
                />
                <p className="mt-1">
                  <b>Matteo Battiston</b>
                </p>
                <p>Head of Design Luxottica Group</p>
                <p>
                  <i>L'head of design</i>
                </p>
              </div>
              <div className="column">
                <HeroImage
                  fileName="jacopo-conti.png"
                  alt="Jacopo Maria Conti"
                />
                <p className="mt-1">
                  <b>Jacopo Maria Conti</b>
                </p>
                <p>Marketing and Content Consultant</p>
                <p>
                  <i>Il retro-gaming expert</i>
                </p>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <HeroImage
                  fileName="nicolas-gentile.png"
                  alt="Nicolas Gentile"
                />
                <p className="mt-1">
                  <b>Nicolas Gentile</b>
                </p>
                <p>Ideatore della Contea Gentile</p>
                <p>
                  <i>Il Daydreamer hero</i>
                </p>
              </div>
              <div className="column">
                <HeroImage fileName="linda-liguori.png" alt="Linda Liguori" />
                <p className="mt-1">
                  <b>Linda Liguori</b>
                </p>
                <p>Naming Expert</p>
                <p>
                  <i>La brand name creator</i>
                </p>
              </div>
              <div className="column">
                <HeroImage fileName="michele-lupi.png" alt="Michele Lupi" />
                <p className="mt-1">
                  <b>Michele Lupi</b>
                </p>
                <p>Men's Collections Visionary di TOD'S Group</p>
                <p>
                  <i>Il community whisperer</i>
                </p>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <HeroImage
                  fileName="antonella-nonino.png"
                  alt="Antonella Nonino"
                />
                <p className="mt-1">
                  <b>Antonella Nonino</b>
                </p>
                <p>Responsabile Premio Internazionale Nonino</p>
                <p>
                  <i>La custode del brand</i>
                </p>
              </div>
              <div className="column">
                <HeroImage fileName="wim-ouboter.png" alt="Wim Ouboter" />
                <p className="mt-1">
                  <b>Wim Ouboter</b>
                </p>
                <p>Founder Micro</p>
                <p>
                  <i>Il retro product-designer</i>
                </p>
              </div>
              <div className="column">
                <HeroImage
                  fileName="giovanni-perosino.png"
                  alt="Giovanni Perosino"
                />
                <p className="mt-1">
                  <b>Giovanni Perosino</b>
                </p>
                <p>Direttore Marketing ITA Airways</p>
                <p>
                  <i>La brand equity star</i>
                </p>
              </div>
            </div>

            <div className="columns has-text-centered is-centered">
              <div className="column is-one-third">
                <HeroImage fileName="mugnaio.png" alt="Piccolo Mugnaio" />
                <p className="mt-1">
                  <b>Piccolo Mugnaio</b>
                </p>
                <p>Personaggio del Mulino Bianco</p>
                <p>
                  <i>L'inspirational hero</i>
                </p>
              </div>
              <div className="column is-one-third">
                <HeroImage
                  fileName="giuliano-trenti.png"
                  alt="Giuliano Trenti"
                />
                <p className="mt-1">
                  <b>Giuliano Trenti</b>
                </p>
                <p>Founder Neurexplore</p>
                <p>
                  <i>Il neuromarketing Pro</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-four-fifths has-text-centered mt-5">
          <h3>Casi di studio</h3>
          <div className="columns has-text-centered mt-3">
            <div className="column">
              <BrandImage fileName="barilla.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="eligo.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="meracinque.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="micro.png" alt="Boolean" />
            </div>
          </div>
          <div className="columns has-text-centered mb-5">
            <div className="column">
              <BrandImage fileName="minolta.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="mulino.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="rayban.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="renault.png" alt="Boolean" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
